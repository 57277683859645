
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { mobileRulesEl } from "@/utils/verify";
import FileUpload from "@/components/scope/file-upload.vue";
import AreaSelect from "@/components/scope/area-select.vue";
import t from "@common/src/i18n/t";
import {DatePicker, TimePicker} from "element-ui";

const management = namespace("management");
const base = namespace("base");
const app = namespace("app");
const setting = namespace("setting");

@Component({
  components: { AreaSelect, FileUpload }
})
export default class managementEdit extends Vue {
  @Prop({ default: true })
  isGiti: boolean;
  @management.Action saveTenant;
  @management.Action getmanagementExpensesRecord;
  @base.Action getDictionaryList;
  @base.Action getVehicleInfoByVin;
  @app.Mutation setPBPX;
  @setting.Action getPcPermissionList;
  @setting.Action getRoleDetail;
  @management.Action getStoreList;
  treeProps = {
    label: "resourceName"
  };
  get breadData() {
    return [
      { name: t("store.store-info"), path: "/management/store-list" },
      { name: t("v210831.edit-store-information") }
    ];
  }
  pcPermList: any = [];
  appPermList: any = [];
  serviceTime = ['0800', '1800'];

  openTime = new Date();
  storeFormData = {
    area: null,
    shopType: "",
    shopK2Name: "",
    starLevel: 0,
    shopPickup: 1,
    growthValue: "0",
    invioce: "",
    storeWorkStations: "",
    contactsName: "",
    contactsPhone: "",
    servicePhone: "",
    serviceTel: "",
    province: "",
    city: "",
    district: "",
    address: "",
    longitude: "",
    latitude: "",
    storeFrontImage: "",
    //
    idcardBackImage: "",
    idcardFrontImage: "",
    k2Name: "",
    k2NumberImage: "",
    legalRepresentative: "",
    legalRepresentativeIdcard: "",
    licenseId: "",
    //
    companyPropertyValue: "",
    payeeAccount: "",
    payeeAddress: "",
    payeeBank: "",
    payeeBankNumber: "",
    payeeName: "",
    payeeSubBank: "",
    taxAddress: "",
    taxBank: "",
    taxBankAccount: "",
    taxCompanyName: "",
    taxPayerIdnumber: "",
    taxPhone: "",
    siteSelector:"",
    recommender:"",
    labels:"",
    serviceScopes:"",
    openingTime: this.serviceTime[0],
    closingTime: this.serviceTime[1],
    storeBizType: "",
    storeBizTypeDesc: "",
    stateCode: "",
    cityCode: "",
    districtCode: "",
  };
  storeList: any = [];
  storeBizTypeList = [
    { label: "自营店", value: 3 },
    { label: "合作店", value: 8 },
    { label: "加盟店", value: 9 }
  ];
  showMobileUsedTips: boolean = false;
  options: any = [];
  storeTypeList = [];
  showAreaSelect: boolean = false; //控制地区信息set完成后，显示组件
  loadEnd: boolean = false;
  get pageType() {
    return this.$route.path === "/management/store-open" ? "open" : "edit";
  }
  handleAreaChange(val) {
    this.$nextTick(() => {
      this.$nextTick(() => {
        const labels = (this.$refs["areaCascader"] as any).getLabels();
        this.storeFormData.province = labels[0];
        this.storeFormData.city = labels[1];
        this.storeFormData.district = labels[2];

        this.storeFormData.stateCode = val[0];
        this.storeFormData.cityCode = val[1];
        this.storeFormData.districtCode = val[2];
      });
    });
  }
  notGitiStoreFormRules: any = {
    area: [{ required: true, message: t("v210831.please-select-the-store-area"), trigger: "change" }],
    address: [{ required: true, message: t("v210831.please-enter-the-adress-detail"), trigger: "blur" }],
    shopK2Name: [{ required: true, message: t("v210831.please-enter-the-store-name"), trigger: "blur" }],
    shopType: [{ required: true, message: "请选择门店类型", trigger: "change" }],
    storeBizType: [{ required: true, message: "请选择门店业务类型", trigger: "change" }],
    contactsName: [{ required: true, message: t("v210831.please-enter-the-principal-name"), trigger: "blur" }],
    contactsPhone: [
      { required: true, message: t("v210831.please-enter-the-principal-mobile"), trigger: "blur" },
      { validator: this.validateMobile, trigger: "blur" }
    ],
    legalRepresentativePhone: [{ validator: this.validateMobile, trigger: "blur" }],
    longitude: [{ required: true, message: t("v210831.please-enter-the-store-longitude"), trigger: "blur" }],
    latitude: [{ required: true, message: t("v210831.please-enter-store-latitude"), trigger: "blur" }],
    payeeName: [{ required: true, message: t("v210831.please-enter-payee-name"), trigger: "blur" }],
    payeeAddress: [{ required: true, message: t("v210831.please-select-the-province"), trigger: "blur" }],
    payeeBank: [{ required: true, message: t("v210831.please-enter-the-bank-name"), trigger: "blur" }],
    payeeSubBank: [{ required: true, message: t("v210831.please-enter-thepayee-bank-branch-name"), trigger: "blur" }],
    payeeBankNumber: [{ required: true, message: t("v210831.please-enter-payee-bank-no-symbol"), trigger: "blur" }],
    payeeAccount: [{ required: true, message: t("v210831.please-enter-the-payee-number"), trigger: "blur" }],
    storeFrontImage:  [{ required: true, message: t("v210831.please-upload-store-img"), trigger: "change" }],
    labels: [{ required: true, message: "请输入门店标签", trigger: "change" }],
    serviceScopes: [{ required: true, message: "请输入经营范围", trigger: "change" }]
  };
  created() {
    this.setPBPX(52);
    this.init();
  }
  validateMobile(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      mobileRulesEl(value, callback);
    }
  }
  getStoreData() {
    if (this.validateForm()) {
      return this.storeFormData;
    } else {
      return false;
    }
  }
  validateForm() {
    let refsKey = [
      "refTenantForm",
      "refOperateForm",
      "refAptitudeForm",
      "refLegalForm",
      "refInvoiceForm",
      "refReceiptForm"
    ];
    let checked = true;
    refsKey.forEach(item => {
      (this.$refs[item] as any).validate(valid => {
        if (!valid) {
          checked = false;
        }
      });
    });
    return checked;
  }
  submitForm() {
    //
  }
  async init() {
    this.showAreaSelect = true;
    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
  }

  searchStore(query){
    this.getStoreList({
      userMobile: query,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      console.log("storeList", data);
      this.storeList = data.data.list;
      return data;
    });
  }

  changeServiceTime(){
    this.storeFormData.openingTime = this.serviceTime[0];
    this.storeFormData.closingTime = this.serviceTime[1];
  }
  checkKeys: any = [];
}
